const EmailIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      height="1em"
      width="1em"
      {...props}
    >
      <circle cx="32" cy="32" fill="#77B3D4" r="32" />
      <path
        d="M32 54c-12.131 0-22-9.869-22-22s9.869-22 22-22 22 9.869 22 22c0 4.521-1.361 8.866-3.938 12.563-.571.821-1.663 1.092-2.552.637-.331-.169-8.094-4.246-8.094-13.117v-8.789c0-1.104.895-2 2-2 1.104 0 2 .896 2 2v8.789c0 4.333 2.641 7.143 4.395 8.529C49.248 37.982 50 35.041 50 32c0-9.925-8.075-18-18-18s-18 8.075-18 18 8.075 18 18 18c2.905 0 5.677-.67 8.238-1.991.979-.508 2.188-.122 2.694.86.507.981.121 2.188-.86 2.694C38.982 53.157 35.499 54 32 54z"
        fill="#FFF"
      />
      <path
        d="M32 24.617c4.071 0 7.383 3.312 7.383 7.383S36.071 39.383 32 39.383 24.617 36.071 24.617 32s3.312-7.383 7.383-7.383m0-4c-6.287 0-11.383 5.096-11.383 11.383S25.713 43.383 32 43.383 43.383 38.287 43.383 32 38.287 20.617 32 20.617z"
        fill="#FFF"
      />
    </svg>
  );
};

export default EmailIcon;
