export default {
  sectionIds: {
    join: 'join-section',
    vacancy: 'vacancy-section',
    warsaw: 'warsaw-section',
    relocation: 'relocation-section',
    feedbacks: 'feedbacks-section',
    company: 'company-section',
  },
};
